<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="page-content w-100">
        <div class="row g-0">
  <!-- <div class="col-sm-12">
    <p class="text-center page-heading">B2B Customers</p>
  </div> -->
</div>
<div class="px-4">
    <div class="row my-2">
      <div class="col-3">
        <label >Name of user</label>
      </div>
      <div class="col-1">
      </div>
      <div class='col-1'>
        <label>Active</label>
      </div>
    </div>
    <div class="row mt-2"  >
      
      <div class="col-3">
        <input type="text"  :disabled="!clinicianOthersPayload.isEditable" :value="customerPayload?.companyName"  autocomplete="off" class="form-control" />
      </div>
      
       <div class="col-1">
        <button type="button" @click="handleInputEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
      <div class="col-1">
        <input type="checkbox" v-model="customerPayload.is_company_active"  name="checkbox"
          :disabled="!clinicianOthersPayload.isEditable" />
      </div> 
    </div>
    <div class="my-3">
<button @click="createCustomer" class="btn btn-blue-color text-white" style="border-radius: 10px;">Add new customer</button>
</div>
<div v-if="addCustomer">
<fieldset class="curve-box" >
  <legend class="subHeadingText">Customer</legend> 
    <div class="row my-2">
        <div class="col-6 ">
            <label>Name</label>
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="companyName"  >
        </div>
        <div class="col-6 ">
            <label>Short Name</label>
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="companyShortName" >
        </div>
    </div>
    <!-- end row -->
    <div class="row my-2">
        <label>Address</label>
        <div class="row my-1">
        <div class="col-6"> 
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="address1">
        </div>
    </div>
        <div class="row my-1">
            <div class="col-6">
                <input type="text" class="form-control cursor UpdateTextAlignment" v-model="address2">
            </div>
        </div>
    </div>
    <div class="row my-2">
        <div class="col-4"> 
            <label>City</label>
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="city">
        </div>
        <div class="col-4">
            <label>Pincode</label>
            <input type="text" maxlength="6" class="form-control cursor UpdateTextAlignment" v-model="pincode">
        </div>
        <div class="col-4 " v-if="addCustomer && !editbutton">
            <label>Upload  logo</label>
        <input @change="uploadLogo($event,index)" placeholder="Upload Logo" type="file" class="form-control cursor UpdateTextAlignment" style="border-radius: 10px;" />
        </div>
        <div class="col-4 " v-if="editbutton && addCustomer">
            <label>Company logo</label>
            <img class="mx-auto d-block" style="width: 200;height: 200px;" :src="`${uploadImage}`"
                alt="logo">
            </div>
    </div>
    <div class="row my-2">
        <div class="col-4"> 
            <label>Website</label>
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="website">
        </div>
        <div class="col-4">
            <label>PAN number</label>
            <input type="text" maxlength="10" class="form-control cursor UpdateTextAlignment" v-model="pan">
        </div>
        <div class="col-4">
            <label>GST number</label>
            <input type="text" maxlength="15" class="form-control cursor UpdateTextAlignment" v-model="gst">
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="active" id="active" v-model="is_company_active" />
            <label class="form-check-label " for="activateORdeactivate">Activate / Deactivate</label>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="curve-box" v-if="addCustomer">
  <legend class="subHeadingText">Activies</legend> 
  <label>Manage</label>
  <div class="row my-2">
        <div class="col-2" v-for="(manageOption, index) in manage" :key="index">
            <label>
                <input type="checkbox" v-model="manageOption.isActive" class="me-2 practiceBox" name="pracriceAreas" 
                    id="pracriceAreas"  />
                {{ manageOption.name }}
            </label>
        </div>
    </div>
    <label>Message</label>
    <div class="row my-2">
        <div class="col-2" v-for="(messageOption, index) in message" :key="index">
            <label>
                <input type="checkbox" v-model="messageOption.isActive"  class="me-2 practiceBox" name="pracriceAreas" 
                    id="pracriceAreas"  />
                {{ messageOption.name }}
            </label>
        </div>
    </div>
    <div class="row my-3" v-if="isOrderOptionActive" >
        <div class=" col-4">
        <label>Product</label>
        <input type="text" class="form-control cursor UpdateTextAlignment"  v-model="product" />
    </div>
    <div class="col-3">
        <label>Order confirmation method by patient</label>
        <select class="form-control consultation-select" name="select" id="select" v-model="order_method">
            <option value="1">OTP</option>
            <option value="name">Diary message</option>
        </select>
    </div>
    </div>
    </fieldset>

    <fieldset class="curve-box" >
  <legend class="subHeadingText">Customer admin</legend>
  <div class="row my-2">
      <div class="col-3">
        <label >Name of user</label>
      </div>
      <div class="col-1">
      </div>
      <div class='col-1'>
        <label>Active</label>
      </div>
    </div>
    <div class="row mt-2" v-for="(value, index) in usersList" :key="index">
      
      <div class="col-3">
        <input type="text"  :disabled="!value.isEditable" :value="value.firstName"  autocomplete="off" class="form-control" />
      </div>
      
       <div class="col-1">
        <button type="button" @click="handleUsersInput(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
      <div class="col-1">
        <input type="checkbox" v-model="value.isActive"  name="checkbox"
          :disabled="!value.isEditable" />
      </div> 
    </div>
    <!-- <div class="my-3">  -->
  <div class="my-3">
<button @click="createUser" class="btn btn-blue-color text-white" style="border-radius: 10px;">Add new user</button>
</div>
<fieldset class="sharp-box" v-if="addUser && is_company_active">
    <legend class="subHeadingText">User</legend> 
<div class="row my-2">
   <div class="col-3">
        <label>User type</label>
        <select class="form-control consultation-select" namxe="select" id="select" v-model="userSelected">
            <option value="Clinician - NMC registered">Clinician - NMC registered</option>
            <option value="Clinician - Others">Clinician - Others</option>
            <option value="Non clinician">Non clinician</option>
        </select>
    </div>
</div>
<div class="row my-2">
       <div class="col-12 col-md-6 col-lg-4 ">
        <label>First name</label>
        <div class="form-group input-group">
        <span class="input-group-addon" v-if="userSelected === 'Clinician - NMC registered'">Dr.</span>
        <input type="text" class="form-control cursor UpdateTextAlignment" v-model="firstName" >
        </div>
      </div>
    <div class="col-12 col-md-6 col-lg-4 ">
        <label>Last name</label>
        <input type="text" class="form-control cursor UpdateTextAlignment" v-model="lastName" >
    </div>
</div>
<div class="row my-2">
        <div class="col-4"> 
            <label>Mail ID</label>
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="userEmail">
        </div>
        <div class="col-4">
            <label>Phone + Whatsapp</label>
            <input type="text" maxlength="10" class="form-control cursor UpdateTextAlignment" v-model="userPhone" >
        </div>
        
    </div>
    <div class="row mt-3">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="active" id="active" v-model="userActive" />
            <label class="form-check-label " for="activateORdeactivate">Activate / Deactivate</label>
          </div>
        </div>
      </div>
    </fieldset>   
    </fieldset>
  <div class="my-3 text-center">
    <button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="editbutton? updateCustomer() : storeCustomer()">Submit</button>
  </div>
</div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
// import moment from 'moment';
export default {
components: {

},
data() {
    return {
    manage:[
    {id:'1',name:'Team',isActive:false},
    {id:'2',name:'Partner clinic',isActive:false},
    {id:'3',name:'Inventory',isActive:false},
    {id:'4',name:'Orders',isActive:false},
    {id:'5',name:'Library',isActive:false},
    ],
    message:[
    {id:'1',name:'Team',isActive:false},
    {id:'2',name:'Indivial clinics',isActive:false},
    {id:'3',name:'All clinics',isActive:false},
    {id:'4',name:'Public',isActive:false}
    ], 
    companyName:'',
    companyShortName:'',
    is_company_active:false,
    // companyAdminName:'',
    // is_company_admin_active:'',
    address1:'',
    address2:'',
    city:'',
    pan:'',
    gst:'',
    product:'',
    order_method:'1',
    pincode:'',
    website:'',
    firstName:'',
    lastName:'',
    userEmail:'',
    userPhone:'',
    customerPayload:{},
    userSelected:'Clinician - NMC registered',
    role:'',
    addUser:false,
    addCustomer:false,
    allCustomers: [],
    editbutton:false,
    updateCustormerData:null,
    publicData:[],
    doctorData:[],
    otherClinician:[],
    updateUsersData:null,
    userActive:false,
    userPayload:{},
    uploadImage: null,
    logoFormData: new FormData(),
    uploadImagePreview:null,
    usersList:[],
    clinicianOthersPayload:{},
    // customerPayload:{},
    editable:false
    }
},
computed:{
    isOrderOptionActive() {
      // Check if the specific option with id '4' is active
      const orderOption = this.manage.find(option => option.id === '4');
      return orderOption ? orderOption.isActive : false;
    },
    mergedData() {
      const mergeData = (doctorData, publicData,otherClinician) => {
        const mergedArray = [];

        const addProperties = (item) => ({
          firstName: item.firstName || null,
          lastName: item.lastName || null,
          email: item.email || null,
          mobile: item.mobile || null,
          role: item.role || null,
          updated_by: item.updated_by || null,
          isActive: item.isActive !== undefined ? item.isActive : null,
          updatedAt: item.updatedAt || null,
          createdAt: item.createdAt || null,
          id:item.id || null
        });

        doctorData.forEach((item) => {
          mergedArray.push(addProperties(item));
        });

        publicData.forEach((item) => {
          mergedArray.push(addProperties(item));
        });

        otherClinician.forEach((item) => {
          mergedArray.push(addProperties(item));
        });

        return mergedArray;
      };

      return mergeData(this.doctorData, this.publicData,this.otherClinician);
    },
},
created: async function () {
    let clinicianothers = localStorage.getItem('clinician-others-id')
    this.clinicianOthersID = JSON.parse(clinicianothers);
    this. getCustomer();
    this.getUsers();
    this.getClinicianOthers();
},
methods: {
    getClinicianOthers(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
     axios
    .get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
    .then((response) => {
        this.clinicianOthersPayload=response.data
        this.customerPayload = this.clinicianOthersPayload.customer
        console.log(this.customerPayload,"this.customerPayload");
    })
},
    uploadLogo(event) {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 300;
            const maxHeight = 300;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const resizedImage = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed
            this.uploadImage = resizedImage;
          };
        };
        reader.onerror = error => {
          console.log('Error: ', error);
        };
      }
    },
    updateDoctor(id,payload){
      if (!this.userPhone) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.userPhone)) {
                return false;
            }
             if (!this.userEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.userEmail)) {
                return false;
            }
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .put(`${BASE_API_URL}/hcps/${id}/update`, payload)
        .then(response =>{
          this.getUsers()
          this.addUser =false
            return response.data

        })
    },
    updateUser(id,payload){
      if (!this.userPhone) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.userPhone)) {
                return false;
            }
            if (!this.userEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.userEmail)) {
                return false;
            }
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .put(`${BASE_API_URL}/public-user/${id}/update`, payload)
        .then(response =>{
          this.getUsers()
          this.addUser =false
            return response.data
        })
    },
    updateOther(id,payload){
      if (!this.userPhone) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.userPhone)) {
                return false;
            }
            if (!this.userEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.userEmail)) {
                return false;
            }
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .put(`${BASE_API_URL}/clinician-others/${id}/update`, payload)
        .then(response =>{
          this.getUsers()
          this.addUser =false
            return response.data
        })
    },
    async getCustomer(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
    .get(`${BASE_API_URL}/admincustomers`)
    .then(response => {
        response.data.map((data)=>{
        const users = JSON.stringify(data.users);
        JSON.parse(users)
          this.allCustomers.push(data)
          })
    })
    },
    async getUsers(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
    .get(`${BASE_API_URL}/hcps`)
    .then(async response => {
        if(response.data){
            this.doctorData = response.data.filter((data)=>{return ( data.updated_by == 'admin-customer') })
            await axios
    .get(`${BASE_API_URL}/public-user`)
    .then(async response =>{
        if(response.data){
            this.publicData=response.data.filter((data)=>{return ( data.updated_by == 'admin-customer') })
            await axios
    .get(`${BASE_API_URL}/clinician-others`)
    .then(response =>{
        if(response.data){
            this.otherClinician=response.data.filter((data)=>{return ( data.updated_by == 'admin-customer') })
        }})
        }})
        }})
    },
    createUser(){
    this.addUser=true;
    },
    createCustomer(){
        this.addCustomer =true
        this.clearDatas();
    },
    handleInputEditState(value, index) {
      this.updateCustormerData =value
      this.editbutton = true;
      this.addCustomer =true
      this.patientSupport = true;
      this.companyName = value?.companyName;
      this.companyShortName = value?.companyShortName;
      this.is_company_active = value?.is_company_active;
      this.address1 = value?.address1;
      this.address2 = value?.address2;
      this.pan = value?.pan;
      this.gst = value?.gst;
      this.city =value?.city;
      this.uploadImage = value?.logo
      this.manage.forEach(manageOption => {
      manageOption.isActive = value.manage.some(activeManage => activeManage.id === manageOption.id);
    });

    this.message.forEach(messageOption => {
      messageOption.isActive = value.message.some(activeMessage => activeMessage.id === messageOption.id);
    });

      this.pincode = value?.pincode;
      this.product = value?.product
      this.order_method = value?.order_method;
      this.website = value.website;
      let previousData = [...this.allCustomers]
      previousData[index].isEditable = true;
      this.allCustomers = previousData;
    this.mergedData.map((data)=>{
      if( data.id == value.users.id&&  data.role == value.users.role){
       this.usersList.push(data);
        // this.handleUsersInput(value.users,value.users)
      }
    })
    },
    handleUsersInput(value, index) {
        this.updateUsersData =value
      this.editbutton = true;
      this.addUser =true
      this.patientSupport = true;
      this.firstName = value?.firstName;
      this.lastName = value?.lastName;
      this.userEmail = value?.email;
      this.userPhone = value?.mobile;
      this.userSelected = value.role == 'hcp'? this.userSelected = 'Clinician - NMC registered' : value.role == 'user' ? this.userSelected ='Non clinician' : this.userSelected ='Clinician - Others'
     this.userActive=value.isActive
      let previousData = [...this.mergedData]
      previousData[index].isEditable = true;
      this.mergedData = previousData;
    },
    clearDatas(){
    this.companyName ='';
    this.companyShortName='';
    this.is_company_active='';
    this.address1='';
    this.address2='';
    this.city='';
    this.pan='';
    this.gst='';
    this.product='';
    this.manage =[{id:'1',name:'Team',isActive:false},
    {id:'2',name:'Partner clinic',isActive:false},
    {id:'3',name:'Inventory',isActive:false},
    {id:'4',name:'Orders',isActive:false},
    {id:'5',name:'Library',isActive:false}];
    this.message =[
    {id:'1',name:'Team',isActive:false},
    {id:'2',name:'Indivial clinics',isActive:false},
    {id:'3',name:'All clinics',isActive:false},
    {id:'4',name:'Public',isActive:false}
    ];
    this.order_method ='1';
    this.pincode ='';
    this.website = '';
    this.firstName ='';
    this.lastName ='';
    this.userEmail = '';
    this.userPhone = '';
    this.userSelected = 'Clinician - NMC registered';
    this.userActive =false;
    },
    async putCustomer(id,payload){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
       this.logoFormData.append('customerData', JSON.stringify(payload));
    await axios
    .put(`${BASE_API_URL}/admincustomers/${id}/update`,payload)
    .then(response => {
      if(response.data){
return response.data
      }
    })
    },
    validMobile(inputtxt){
            const mobilevalidation = /^[6-9]\d{9}$/;
            if(inputtxt.match(mobilevalidation)){
                return true
            }else{
                this.$swal("Please enter the correct mobile number!");
                return false;
            }
        },
    validEmail(inputtxt) {
        var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var consecutiveSpecialChar = /[\W]{2}/;

        if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
            return true;
        } else {
            this.$swal("Please enter the correct email ID!");
            return false;
        }
    },
   async storeCustomer(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    this.customerPayload = {
    companyName: this.companyName,
    companyShortName: this.companyShortName,
    is_company_active: this.is_company_active,
    address1: this.address1,
    address2:this.address2,
    city:this.city,
    pan: this.pan,
    gst: this.gst,
    product: this.product,
    manage:this.manage.filter((data)=>{return (data.isActive == true)}),
    message:this.message.filter((data)=>{return (data.isActive == true)}),
    order_method: this.order_method,
    pincode: this.pincode,
    website: this.website,
    logo :this.uploadImage
    }
    this.userPayload = {
            firstName : this.firstName,
            lastName : this.lastName,
            email : this.userEmail,
            mobile : this.userPhone,
            role: this.userSelected == 'Clinician - NMC registered'? 'hcp' : this.userSelected == 'Non clinician' ? 'user' : 'clinician-others',
            updated_by:'admin-customer',
            isActive:this.userActive,
            practiceId:[],
            doctorPatientId:[],
            Doctorconsultaion:[],
            consultation_id:[]
        }
        if (!this.userPhone) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.userPhone)) {
                return false;
            }
            if (!this.userEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.userEmail)) {
                return false;
            }
        if (this.customerPayload) {
            this.logoFormData.append('customerData', JSON.stringify(this.customerPayload));
    await axios
    .post(`${BASE_API_URL}/admincustomers`, this.customerPayload)
    .then(async response => {
      if(response.data){
        const customerid=response.data.id
        this.userPayload.customer = response.data;
        if(this.userPayload.role == 'hcp'){
        await axios
        .post(`${BASE_API_URL}/hcps`, this.userPayload)
    .then(async response =>{
     const payload={
        users:response.data
      }
        if (payload.users) {
        this.putCustomer(customerid,payload)
        }})
    }else if(this.userPayload.role == 'user'){
         axios
        .post(`${BASE_API_URL}/public-user`, this.userPayload)
        .then(async response =>{
          const payload={
        users:response.data
      }
        if (payload.users) {
        this.putCustomer(customerid,payload)
        }})
    }
    else if(this.userPayload.role == 'clinician-others'){
         axios
        .post(`${BASE_API_URL}/clinician-others`, this.userPayload)
        .then(async response =>{
          const payload={users:response.data}
        if (payload.users) {
        this.putCustomer(customerid,payload)
        } })
    }                                     
        this.clearDatas();
        this.getCustomer();
        this.getUsers()
        this.addCustomer =false
        this.addUser =false
      }
    })
    }
    },
    async updateCustomer(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    this.customerPayload = {
    companyName: this.companyName,
    companyShortName: this.companyShortName,
    is_company_active: this.is_company_active,
    address1: this.address1,
    address2:this.address2,
    city:this.city,
    pan: this.pan,
    gst: this.gst,
    product: this.product,
    manage:this.manage.filter((data)=>{return (data.isActive == true)}),
    message:this.message.filter((data)=>{return (data.isActive == true)}),
    order_method: this.order_method,
    pincode: this.pincode,
    website: this.website,
    }
    if (this.updateCustormerData) {
    await axios
    .put(`${BASE_API_URL}/admincustomers/${this.updateCustormerData.id}/update`, this.customerPayload)
    .then(response => {
      const payload ={
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.userEmail,
      mobile : this.userPhone,
      role: this.userSelected == 'Clinician - NMC registered'? 'hcp' : this.userSelected == 'Non clinician' ? 'user' : 'clinician-others',
      updated_by:'admin-customer',
      practiceId:[],
      doctorPatientId:[],
      Doctorconsultaion:[],
      consultation_id:[],
      customer : response.data
      }
        if(response.data.users?.role == 'hcp'){
          payload.isActive = this.is_company_active
            this.updateDoctor(response.data.users?.id,payload);

        }else if(response.data.users?.role == 'user'){
          payload.isActive = this.is_company_active
            this.updateUser(response.data.users?.id,payload)

        } else if(response.data.users?.role == 'clinician-others'){
          payload.isActive = this.is_company_active
            this.updateOther(response.data.users?.id,payload)
        }
       
        return response.data
    })
    }
        this.getCustomer();
        this.addCustomer =false
        this.clearDatas();
        this.usersList = [];
    }
}
}
</script>
<style>
.consultation-select{
width: 400px;
font-size: 20px;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.uploadImgBtn{
    border: 2px solid #00979e;
}
 fieldset.curve-box {
        border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
        margin-top: 1.5rem;
    }

fieldset.sharp-box {
    border: 2px solid #00979e;
    /* border-radius: 20px; */
    padding: 15px;
    margin-top: 1.5rem;
}

legend.subHeadingText {
    /* background: rgb(243, 242, 239); */
    padding: 4px 10px;
    /* margin: 0 auto; */
    display: block;
    float: none;
    width: auto;
    font-size: 20px;
}
.UpdateTextAlignment{
    text-align: left !important;
}
</style>